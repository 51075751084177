@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

html {
  background: $gray_light-2;

}

.page_content_subscription {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .container {
    // padding: 0 96px 60px;
    width: 680px;
    margin: 0 auto;
    .main_container {
      .dropDownManager {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 43px 0 26px;
        svg {
          cursor: pointer;
        }
        .open {
          cursor: pointer;
        }
        .close {
          cursor: pointer;
          margin-left: 30px;
        }
      }
    }
    .form_sub {
      display: flex;
      flex-direction: column;
      padding-bottom: 220px;
    }
  }
}

@media only screen and (min-width : 1460px){
  
  .page_content_subscription {
    .container {
      padding: 0 0 60px;
      width: 96%;
      .main_container {
        width: 1400px;
        margin: 0 auto;
        .dropDownManager {
          width: 870px;
        }
        .form_sub {
          display: flex;
          flex-direction: row;
          .wrapper {
            .container_bloc {
              width: 870px;
            }
            .container_confirmSub {
              header {
                cursor: default;
              }
            } 
          }
          .container_resume {
            &.show {
              display: flex;
              height: fit-content;
              margin-left: 30px;
              position: sticky;
              align-self: flex-start;
              top: 10%;
              overflow-y: auto;
            }
            main {
              padding: 0 56px;
            }
          }
        }
      }
     
    }
  }
  .header_container {
    width: 100%;
  }
}