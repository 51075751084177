@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.page_content_auth {
  background: $blue_accueil;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .container_white {
    background: $white;
    @include shadow;
    border-radius: 8px;
    display: flex;
    padding: 30px 75px;
    width: 500px;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    position: relative;
    h1 {
      text-align: center;
    }
    p {
      // text-align: center;
      // padding:0 54px;
      margin-top: 2px;
    }
    .logoSmall-bg {
      position: absolute;
      bottom: -210px;
      right: -25px;
    }
    form {
      // width: 60%;
      .form {
        display: flex;
        flex-direction: column;
        border: none;
        width: 300px;
      }
      .error-message {
        opacity: 0;
        margin: 15px 0 10px;
        color: $red_alert;
        text-align: center;
        &.isError {
          opacity: 1;
        }
      }
      .green {
        // max-width: 200px;
        margin: 0 auto;
      }

      .forgotPassword {
        text-decoration: underline;
        cursor: pointer;
      }

      .rememberMe {
        margin-bottom: 10px;
        .checkboxButton {
          padding: 0;
          span {
            padding-left: 0;
          }
        }
      }
    }
  }
  .logoBig-bg {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
