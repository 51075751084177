@import '../../core/styles/colors.scss';

@keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
    0% {
        opacity: 0;
    }
  }

.loading {
    background: $blue_accueil;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: .5s ease-out show;    
    svg {
        width: 100%;
        height: 100%;
    }
}