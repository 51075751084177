@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.parametrage_container {
   height: 100%;
   background-color: $white;
   border-radius: 10px;
   // position: relative;
   overflow: hidden;
   padding: 28px 28px 12px;
   @include shadow;
   position: relative;
}

.parametrage_notes {
   margin-top: 36px;
   padding-top: 24px;
   border-top: 1px solid lightgrey;
}

.parametrage_droits {
   margin-top: 36px;
   padding-top: 24px;
   border-top: 1px solid lightgrey;
}

// .parametrage_header {
//    position: sticky;
//    top: 0;
//    left: 0;
//    background: white;
//    z-index: 1000;
// }

// .parametrage_content {
// }
