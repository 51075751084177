@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";


.simulate_input_label {
  font-family: "Circular-Std", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 14px;
  color: $blue_petrol_strong;

}
.team_container {
  border: 1px solid $gray_medium;
  border-radius: 10px;
  padding: 10px 10px 0 10px;
  margin-bottom: 24px;
  .popin_checkbox {
    border-radius: 10px;
    background: $gray_light-2;
  }
  .green-v2 {
    float: right;
  }
}

.alert {
  color: red;
  font-size: 14px;
}
.popinModifyUser .cross {
  top:30px;
}


