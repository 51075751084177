@import '../../core/styles/colors.scss';

.lien {
    position: relative;
    text-decoration: none;
    &:before {
        content: "";
        background: $green;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 1px;
    }
    svg {
        transform: rotate(90deg);
        height: 10px;
        width: 8px;
        margin-left: 3px;
        path {
            fill: $green;
        }
    }
    &:hover {
        svg {
            path {
                fill: $green_light;
            }
        }
        &:before {
            background: $green_light;
        }
    }
    &.txt_open {
        svg {
            transform: rotate(-90deg);
        }
    }
    
}