@import "../../../../core/styles/mixins.scss";
@import "../../../../core/styles/colors.scss";

.toast {
  transition: right 0.4s ease-out;
  position: fixed;
  z-index: 11000;
  width: 353px;
  min-height: 209px;
  height: auto;
  top: 70px;
  right: -353px;
  background: url(../../../../core/assets/picto/logoBig.svg),
    radial-gradient(
      87.46% 87.46% at 100% 107.96%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 0.01%,
      rgba(255, 255, 255, 0) 100%
    );
  background-color: #2896af;
  background-blend-mode: saturation;
  background-position-x: right;
  background-position-y: center;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  padding: 40px 35px 30px;
  box-sizing: border-box;
  overflow: hidden;
  @include shadow;
  display: none;

  &.show {
    display: block;
    animation-name: slideLeft;
    animation-duration: 6s;
  }
  .content {
    z-index: 1300;

    .titre {
      //styleName: Titles/H2;
      font-size: 25px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: $white;
      margin: 0;
      margin-bottom: 8px;
    }
    .description {
      //styleName: Texte - 14 Book;
      font-size: 14px;
      font-weight: 450;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $white;
      margin: 0;
      margin-bottom: 8px;
    }
    .cible {
      //styleName: Texte - 16 Bold;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
      color: $white;
    }
  }

  .crossNotif {
    position: absolute;
    top: 13px;
    right: 20px;
    cursor: pointer;
    z-index: 1300;
  }
  .round-time-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .round-time-bar div {
    height: 5px;
    background: linear-gradient(to right, $blue_light, $blue_accueil);
    transform-origin: left center;
  }

  .round-time-bar[data-style="smooth"] div {
    animation: roundtime calc(var(--duration) * 1s) linear forwards;
  }

  @keyframes roundtime {
    to {
      /* More performant than animating `width` */
      transform: scaleX(0);
    }
  }

  @keyframes slideLeft {
   
    0% {
      display:block;
      opacity: 0;
      right: -353px;
    }
    5% {
      opacity: 0.3;
    }
    10% {
      opacity: 0.8;
    }
    13% {
      opacity: 1;
    }
    16% {
      opacity: 1;
      right:0;
    }
    55% {
      opacity: 1;
      right:0;
    }
    65% {
      opacity: 1;
      right:0;
    }
    75% {
      opacity: 1;
      right:0;
    }
    85% {
      opacity: 1;
      right:0;
    }
    95% {
      opacity: 1;
      right:0;
    }
    100% {
      animation-name: exitSlideLeft;
      animation-duration: 0.4s;
    }
    
  }

  @keyframes exitSlideLeft {
   
    0% {
      opacity: 1;
      right: 0;
    }
   
    100% {
      opacity: 0;
      display: none;
      right:-353px;
    }

    
    
  }
}
