@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.utilisateurs_container {
  height: calc(100vh - 160px);
  background-color: $white;
  padding-bottom: 4px;
  border-radius: 10px;
  // position: relative;
  overflow: hidden;
  @include shadow;
}

.utilisateurs_content_wrapper {
  height: calc(100% - 160px);
  &.filterShow {
    height: calc(100% - 255px);
  }
  margin-right: 10px;
  overflow-y: scroll;
  margin-left: 28px;
  // overflow-x: hidden;
  // position: relative;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: rgb(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: $green;
    border-radius: 10px;
  }
  
}

.utilisateurs_content {
  padding: 0 12px 0 0;
  width: inherit;
  position: static;
  .MuiTable-root {
    margin-bottom: 15px;
    border-spacing: 0 10px;
    .MuiTableHead-root {
      .MuiTableRow-head {
        th:nth-last-child(-n+2) {
         svg {
          display: none;
         }
        }
      }
    }
  }
}

.utilisateurs_header {
  padding: 28px 28px 12px;
  position: sticky;
  top: 0;
  left: 0;
  background: white;
  z-index: 1000;
}

.top {
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
  }

  .left {
    display: flex;
    align-items: baseline;
    h2 {
      margin-right: 16px;
    }
    button {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.cta {
  display: flex;
  flex-direction: row;
  .button-list {
    border-right: 1px solid black;
    display: flex;
    flex-direction: row;
  }
}

.filter_infos {
  color: $blue_petrol_strong;
  font-weight: 700;
  margin: 10px 0 20px;
}

.utilisateurs_header_left {
  display: flex;
  justify-content: left;
}

.utilisateurs_header_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.utilisateurs_header_filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $gray_light-2;
  border-radius: 10px;
  padding: 10px 10px 5px 15px;
  margin-bottom: 20px;
  div {
    margin-right: 5px;
  }
  .blue {
    padding: 10px 19px 6px;
    font-size: 14px;
    line-height: 1;
  }

  .container_combobox {
    .MuiFormControl-root {
      min-width: 180px;
      .MuiInputLabel-root {
        top: 0;
        &.MuiFormLabel-filled,
        &.Mui-focused {
          top: -8px;
        }
      }
      .MuiOutlinedInput-root .MuiInputBase-input {
        padding: 5px 10px;
      }
    }
  }
}

// overwrite modal select
#menu- .MuiPaper-elevation1 {
  max-width: 100% !important;
  width: auto !important;
  border-radius: 8px;
  margin-top: 10px;
  // left: 0 !important;
  // padding: 6px 0;
}

.wrapper_tooltip {
  position: relative;

  .wrapped_tooltip {
    position: absolute;
    top: 40px;
    left: -200px;
    float: right;
    padding: 10px 14px;
    margin: 0;
    min-width: 200px;
    border-radius: 10px;
    border: 2px solid $gray_light;
    width: fit-content;
    background: $white;
    @include shadow;
    p {
      margin: 0;
      overflow-wrap: break-word;
    }
  }
}
