@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.vente_container {
 
  min-height: calc(100vh - 160px);
  // height:  calc(100vh - 160px);
  height: 100%;
  background-color: $white;
  padding-bottom: 4px;
  border-radius: 10px;
  // position: relative;
  overflow: hidden;
  @include shadow;
  .pagination_wrapper {
    .pagination_container {
      flex-wrap: wrap;
    }
  }
 
}

.vente_content_wrapper {
  height: calc(100% - 185px);
  &.filterShow {
    height: calc(100% - 285px);
  }
  margin-right: 10px;
  margin-left: 28px;
  overflow-y: scroll;
  // overflow-x: hidden;
  // position: relative;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: rgb(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: $green;
    border-radius: 10px;
  }
  
}

.vente_content {
  padding: 0 12px 0 0;
  width: inherit;
  position: static;
  .MuiTable-root {
    border-spacing: 0 10px;
    .statut-date {
      line-height: 1;
      .text_motif {
        .motif {
          display: none;
        }
      }
    }
    .MuiTableRow-root {
      display: none;
    }
    .MuiTableRow-root.custom-table-row, .MuiTableRow-root.MuiTableRow-head {
      display: table-row;
      cursor: pointer;
    }
  }

}

.vente_header {
  padding: 28px 28px 12px;
  position: sticky;
  top: 0;
  left: 0;
  background: white;
  z-index: 1000;
}

.top {
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
  }

  .left {
    display: flex;
    align-items: baseline;
    h2 {
      margin-right: 16px;
    }
    button {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.cta {
  display: flex;
  flex-direction: row;
  .button-list {
    display: flex;
    flex-direction: row;
    border-right: none;
  }
  .searchField{
    .MuiFormControl-root {
      margin: 0 8px ;
      width: 230px;
      &:hover {
        .MuiInputLabel-root {
          color: $text;
        }
      }
  
      .MuiInputLabel-root {
        font-family: "Circular-Std", Arial, sans-serif;
        font-size: 0.9rem;
        line-height: inherit;
        .MuiInputLabel-asterisk {
          color: $red_alert;
        }
      }
  
      .MuiOutlinedInput-root {
        background: $gray_light-2;
        border-radius: 5px;
        &:hover {
          fieldset {
            border-color: $gray_strong;
          }
        }
        .MuiInputBase-input {
          padding: 5px 10px;
          font-size: 14px;
          background: $gray_light-2;
          font-family: "Circular-Std-book", Arial, sans-serif;
        }
        &.Mui-disabled {
          fieldset {
            border-color:$gray_light-2;
          }
          &:hover {
            fieldset {
              border-color:$gray_light-2;
            }
          }
        }
        .endAdornmentHT {
          display: flex;
          width: 50px;
          justify-content: flex-end;
        }
      }
  
      fieldset {
        border-color: $gray_medium;
        legend {
          max-width: 0;
        }
      }
      // when focused
      .Mui-focused,
      .MuiFormLabel-filled {
        &.MuiInputLabel-root {
          color: $blue_petrol_strong !important;
          top: -8px;
        }
  
        fieldset {
          border-width: 1px !important;
          border-color: $blue_petrol_strong !important;
          legend {
            max-width: 0;
          }
        }
      }
      
    }
  }
}

.filter_infos {
  color: $blue_petrol_strong;
  font-weight: 700;
  margin: 10px 0 20px;
}

.vente_header_left {
  display: flex;
  justify-content: left;
}

.vente_header_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.vente_header_filters {
  display: flex;
  align-items: stretch;
  background-color: $gray_light-2;
  border-radius: 10px;
  padding: 10px 10px 10px 15px;
  margin-bottom: 20px;
  justify-content: space-between;
  .vente_header_filters_container {
    display: flex;
    flex-wrap: wrap;
  
    .inputText, .container_combobox, .container_combobox_multi, .input-text {
      margin-right: 8px;
      .MuiFormControl-root {
        margin-bottom: 8px;
      }
    }
    .inputText {
      .MuiOutlinedInput-root {
        padding-right: 0;
      }
    }
    .datepicker {
      .input-text {
        .MuiFormControl-root {
          .MuiOutlinedInput-root .MuiInputBase-input {
            padding: 9px 15px;
          }
          .MuiInputLabel-root {
            top: -7px;
          }
        }
      } 
    }
    .inputFilter_number {
      .input-text {
        .MuiFormControl-root {
          .MuiOutlinedInput-root {
            width: 120px;
            .MuiInputBase-input {
              padding: 9px 15px;
            }
          }
          .MuiInputLabel-root {
            top: -7px;
            &.MuiFormLabel-filled{
              top: -8px;
            }
          }
        }
      }  
    }
    .container_combobox_multi {
      .MuiFormControl-root {
        min-width: 150px;
        .MuiOutlinedInput-root .MuiInputBase-input {
          padding: 5px 15px;
        }
        .MuiInputLabel-root {
          top: 0;
          &.MuiFormLabel-filled, &.Mui-focused{
            top: -8px;
          }
        }
      } 
    }
    .inputText {
      .MuiFormControl-root {
        .MuiOutlinedInput-root .MuiInputBase-input {
          padding: 9px 0 9px 15px;
          width: 95px;
        }
        .MuiInputAdornment-root {
          margin-left: 0;
        }
        .MuiInputLabel-root {
          top: -7px;
          &.MuiFormLabel-filled,  &.Mui-focused{
            top: -8px;
          }
        }
      }

    } 
  }
  
  .blue {
    padding: 10px 19px 6px;
    font-size: 14px;
    margin-top: 15px;
    line-height: 1;
  }

  .container_combobox {
    .MuiFormControl-root {
      min-width: 180px;
      .MuiInputLabel-root {
        top: 0;
        &.MuiFormLabel-filled,
        &.Mui-focused {
          top: -8px;
        }
      }
      .MuiOutlinedInput-root .MuiInputBase-input {
        margin-right: 20px;
        padding: 5px 10px;
      }
    }
  }
}

// overwrite modal select
#menu- .MuiPaper-elevation1 {
  max-width: 100% !important;
  width: auto !important;
  border-radius: 8px;
  margin-top: 10px;
  // left: 0 !important;
  // padding: 6px 0;
}

.wrapper_tooltip {
  position: relative;

  .wrapped_tooltip {
    position: absolute;
    top: 40px;
    left: -200px;
    float: right;
    padding: 10px 14px;
    margin: 0;
    min-width: 200px;
    border-radius: 10px;
    border: 2px solid $gray_light;
    width: fit-content;
    background: $white;
    @include shadow;
    p {
      margin: 0;
      overflow-wrap: break-word;
    }
  }
}

// Pagination
.pagination {
    .MuiTablePagination-toolbar {
        .MuiTablePagination-selectLabel, .MuiInputBase-root {
            font-weight: 500;
            font-size: 12px;
            font-family: "Circular-Std", Arial, sans-serif;
        }
        .MuiTablePagination-displayedRows {
            // display: none;
        }
       
    }
}


// Responsive 
@media only screen and (max-width : 2326px){
  .vente_content_wrapper.filterShow {
    height: calc(100% - 340px);
  }
}
// @media only screen and (min-width : 2209px){
//   .vente_content_wrapper.filterShow {
//     height: calc(100% - 283px);
//   }
// }
@media only screen and (max-width : 1460px){
  .vente_content_wrapper {
    &.filterShow {
      height: calc(100% - 340px);
    }
  }
  
}
@media only screen and (max-width : 1418px){
  .vente_content_wrapper {
    &.filterShow {
      height: calc(100% - 395px);
    }
  }
  
}

@media only screen and (max-width : 1250px){
  .vente_content_wrapper {
    height: calc(100% - 185px);
    
  }
}
@media only screen and (max-width : 1236px){
  .vente_content_wrapper {
    height: calc(100% - 205px);
    &.filterShow {
      height: calc(100% - 415px);
    }
  }
}
@media only screen and (max-width : 1228px){
  .vente_content_wrapper {
    height: calc(100% - 205px);
    &.filterShow {
      height: calc(100% - 470px);
    }
  }
}
@media only screen and (max-width : 1200px){
  .vente_container {
    .pagination_wrapper  {
      .pagination_container {
        align-items: center;
        justify-content: space-between;
        .pagination_row {
          width: auto;
        }
      }
    }
  }
}
@media only screen and (max-width : 1150px){
  .vente_header {
    .top {
      .left {
        flex-wrap: wrap;
      }
    }
  }
}
@media only screen and (max-width : 1137px){
  .vente_content_wrapper {
    height: calc(100% - 225px);
    &.filterShow {
      height: calc(100% - 490px);
    }
  }
}
@media only screen and (max-width : 1074px){

  .vente_content_wrapper {
    height: calc(100% - 235px);
    &.filterShow {
      height: calc(100% - 500px);
    }
  }

}

@media only screen and (max-width : 1063px){
  .vente_content_wrapper {
    &.filterShow {
      height: calc(100% - 490px);
    }
  }
}

@media only screen and (max-width : 1055px){
  .vente_container {
    .pagination_wrapper  {
      .pagination_container {
        justify-content: center;
        .pagination_row {
          margin-top: 10px;
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
  .vente_content_wrapper {
    height: calc(100% - 290px);
    &.filterShow {
      height: calc(100% - 555px);
    }
  }

}
@media only screen and (max-width : 1042px){

  .vente_content_wrapper {
    &.filterShow {
      height: calc(100% - 610px);
    }
  }

}
@media only screen and (max-width : 1024px){
  .vente_container {
    .pagination_wrapper  {
      .pagination_container {
        justify-content: space-between;
        .pagination_row {
          margin-top: 0;
          width: auto;
          justify-content: flex-end;
        }
      }
    }
  }
  .vente_content_wrapper {
    height: calc(100% - 190px);
    &.filterShow {
      height: calc(100% - 392px);
    }
  }
}

@media only screen and (max-width : 965px){
  .vente_content_wrapper {
    height: calc(100% - 205px);
    &.filterShow {
      height: calc(100% - 470px);
    }
  }
}
@media only screen and (max-width : 865px){
  .vente_content_wrapper {
    height: calc(100% - 225px);
    &.filterShow {
      height: calc(100% - 490px);
    }
  }
}

@media only screen and (max-width : 802px){
  .vente_content_wrapper {
    height: calc(100% - 235px);
    &.filterShow {
      height: calc(100% - 500px);
    }
  }
}
@media only screen and (max-width : 791px){
  .vente_content_wrapper {
    &.filterShow {
      height: calc(100% - 500px);
    }
  }
}
@media only screen and (max-width : 783px){
  .vente_container {
    .pagination_wrapper  {
      .pagination_container {
        justify-content: center;
        .pagination_row {
          margin-top: 10px;
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
  .vente_content_wrapper {
    height: calc(100% - 292px);
    &.filterShow {
      height: calc(100% - 555px);
    }
  }
}
@media only screen and (max-width : 770px){
  .vente_content_wrapper {
    
    &.filterShow {
      height: calc(100% - 610px);
    }
  }
}

@media only screen and (max-width : 700px){
  .vente_header {
    .top {
      flex-direction: column;
      .left {
        margin-bottom: 10px;
      }
      .cta .searchField .MuiFormControl-root {
        margin-left: 0;
      }
    }
  }
  .vente_content_wrapper {
    height: calc(100% - 245px);
    &.filterShow {
      height: calc(100% - 565px);
    }
  }
  .vente_content_wrapper {
    height: calc(100% - 300px);
    &.filterShow {
      height: calc(100% - 620px);
    }
  }
 
}

