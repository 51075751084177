@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.societes_container {
    height: calc(100vh - 160px);
    background-color: $white;
    padding-bottom: 4px;
    border-radius: 10px;
    // position: relative;
    overflow: hidden;
    @include shadow;
  }

.societes_content_wrapper {
    height: calc(100% - 160px);
    margin-right: 10px;
    overflow-y: scroll;
    // overflow-x: hidden;
    // position: relative;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: rgb(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: $green;
      border-radius: 10px;
    }
    
  }
  
  .societes_content {
    padding: 0 12px 0 28px;
    width: inherit;
    position: static;
    .MuiTable-root {
      margin-bottom: 10px;
      border-spacing: 0 10px;
    }
  }