@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.organize_modal {
    position: absolute;
    background: $white;
}

.modal_relative {
    position: relative;
}