@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.popin {
  background-color: white;
  width: 680px;
  max-height: 500px;
  padding: 50px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  border-radius: 10px;
  @include shadow;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  .barreButton {
    margin-top: 0;
  }
}

.page_content_transfermass {
  position: relative;
}

.title {
  margin: 27px 0 15px 0;
  color: $green;
}

.subtitle {
  margin: 0;
  color: $blue;
}

#label-radio-group-user-type {
  font-weight: 500;
}

.error_wrapper {
  border: 1px solid $red_alert;
  border-radius: 4px;
}

.type_error {
  color: $red_alert;
  top: 48px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 0px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.main_container_transfermass {
  margin-top: 15px;
}

.popin_transfermass_header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cross {
  position: absolute;
  top: 56px;
  right: 50px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;

  path {
    color: $text;
    fill: $text;
  }
}

.simulate_input_label {
  font-family: "Circular-Std", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 14px;
  color: $blue_petrol_strong;
}
.popin_team_container {
  background-color: $white;
  border: 1px solid $gray_medium;
  border-radius: 10px;
  padding: 10px 10px 0 10px;
  margin-bottom: 24px;
  .popin_checkbox {
    border-radius: 10px;
    background: $gray_light-2;
  }
  .green-v2 {
    float: right;
  }
}

.alert {
  color: red;
  font-size: 14px;
}

.tagSelectRespo {
  color: $white;
  background-color: $blue;
  border-radius: 10px;
  width: fit-content;
  padding: 8px 12px;
  margin: 14px 0;
}

.team_container {
  border: 1px solid $gray_medium;
  border-radius: 10px;
  padding: 10px 10px 4px 10px;
  margin-bottom: 24px;
  .popin_checkbox {
    border-radius: 10px;
    background: $gray_light-2;
  }
  .green-v2 {
    float: right;
  }
}
