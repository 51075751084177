@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.menu_desk {
  width: 235px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 66px;
  .wrapper_btn {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0 8px 0;
    .arrowMenu {
      background: none;
      svg {
        transform: rotate(-180deg);
      }
      &:hover {
        background: transparent !important;
        border-color: transparent !important;
      }
    }
  }
  &.open {
    transition: ease 0.2s 0.2s;
    .nav_desk {
      margin-top: 0;
      border-radius: 10px;
      li {
        p {
          transition: ease 0.2s 0.3s;
          opacity: 1;
          font-size: 14px;
        }
      }
    }
    .TCO {
      min-height: 125px;
    }
  }
  &.closed {
    transition: ease-in 0.2s;
    width: 60px;
    .wrapper_btn {
      .arrowMenu {
        svg {
          transform: rotate(0);
        }
      }
    }
    .nav_desk {
      margin-top: 0;

      li {
        p {
          cursor: default;
          transition: ease 0.2s 0.1s;
          transition: height ease 0s 0.3s;
          width: 0;
          height: 0;
          opacity: 0;
        }
      }
    }
    .TCO {
      transition: ease 0.3s 0.4s;
      padding: 0;
      height: 60px;

      button {
        padding: 15px 14px 12px;
        margin: 0;
        &.closed {
          opacity: 0;
          border-radius: 10px;
          transition: ease-out 0.3s;
        }
        &.opacity {
          opacity: 1;
        }
        svg {
          margin-right: 0;
        }
      }
      h4,
      p {
        transition: ease-in-out 0s;

        opacity: 0;
        height: 0;
        width: 0;
        margin: 0;
      }
    }
  }
  // 
  .nav_desk {
    list-style: none;
    @include shadow;
    padding: 0;
    border-radius: 10px;
    li {
      background: $green;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: uppercase;
      height: 58px;
      cursor: pointer;
      padding: 0 20px;
      &.selected {
        background: $green_strong;
      }
      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        color: $white;
        margin: 0 0 0 10px;
        padding-top: 2px;
      }
      &:hover {
        background: $green_light;
      }
      &:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &:last-of-type {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
  .TCO {
    transition: ease-in-out 0.3s 0.1s;
    background: $white;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    @include shadow;
    h4 {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      text-transform: none;
      font-weight: 700;
      transition: ease-in-out 0.3s 0.5s;
    }
    p {
      font-size: 14px;
      transition: ease-in-out 0.3s 0.5s;
    }
    button {
      text-align: left;
      min-height: 60px;
      &:hover {
        background: $blue_petrol_strong !important;
        border-color: $blue_petrol_strong !important;
      }
      svg {
        width: 30px;
        height: 19px;
        margin-right: 10px;
      }
      font-size: 15px;
      p {
        color: $white;
        margin-block-start: 18px;
      }
    }
  }
}

// Menu right
.menu_right {
  .btn_userRight {
    &.open {
      background: $green_strong;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
