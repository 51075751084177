@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.page_content_mdp {
  background: $blue_accueil;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .container_white {
    background: $white;
    @include shadow;
    border-radius: 8px;
    display: flex;
    padding: 30px;
    width: 80%;
    max-width: 620px;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    position: relative;
    h1 {
      text-align: center;
    }
    .blue {
      text-align: center;
      padding: 0 35px;
      margin-bottom: 22px;
    }
    .logoSmall-bg {
      position: absolute;
      bottom: -210px;
      right: -25px;
    }
    .container_btn {
      display: flex;
      flex-direction: column;
      button {
        &:first-of-type {
          margin-bottom: 20px;
        }
      }
    }
  }
  .container_step {
    .container_form {
      width: 325px;
      margin: 0 auto;
    }
    .container_btn {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      button {
        &:first-of-type {
          margin-right: 15px;
        }
      }
    }
  }
  .logoBig-bg {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .password_checker {
    background: $white;
    @include shadow;
    border-radius: 8px;
    border: 1px solid $green;
    padding: 10px;
    width: 175px;
    position: absolute;
    top: 45%;
    left: 92%;
    transform: translate(-50%, -50%);
    z-index: 10;
    .checker {
      display: flex;
      align-items: center;
      p {
        margin: 6px;
        margin-left: 7px;
        text-align: left;
        padding: 0;
        font-size: 12px;
        &.isOk {
          text-decoration: line-through;
        margin-left: 12px;

        }
      }
    }
    .custom_arrow {
      position: absolute;
      top: 55%;
      left: -11px;
    }
 
    
  }
}

