// Base color

$white: #FFFFFF;
$text: #0E0E0E;

$gray_strong: #272727;
$gray_medium: #9D9D9D;
$gray_light: #F0F0F0;
$gray_light-2: #F8F8F8;

$green: #00644B;
$green_25:  rgba(0, 100, 75, 0.25);
$green_light:#109F7B;
$green_strong: #104535;
$green_line: #e8f0ee;

$blue:#0ABBF0;
$blue_25:rgba(10, 187, 240, 0.25);
$blue_petrol_strong: #2896AF;
$blue_light: #E8F0EE;
$blue_accueil: #80DCF7;

$red_alert_10: rgba(206, 37, 37, 0.1);
$red_alert: #CE2525;