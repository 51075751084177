@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

html {
  background: $gray_light-2;
}

.blue {
  color: $blue;
}

.margin_left {
  margin-left: 12px;
}

.page_content_validation {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .container {
   
    width: 680px;
    margin: 0 auto;
    .main_container {
      width: 680px;
    }
    .validation_bloc {
      margin: 50px auto 0;
      @include shadow;
      flex-direction: column;
      position: relative;
      background: $white;
      padding: 36px 30px;

      border-radius: 8px;
      .margin_container {
        margin: 36px 0;
      }
      .text_container {
        text-align: center;
        max-width: 480px;
        margin: 0 auto;
        .sm_margin_bot {
          margin-bottom: 4px;
        }
        .sm_margin_top {
          margin-top: 0;
        }
      }
      .dynamic_container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }
      .btn_margin {
        margin: 36px auto 0;
      }
    }
  }
}

@media only screen and (min-width : 1460px){
  .page_content_validation .container {
    padding: 0 0 60px;
    width: 96%;
    .main_container {
      margin: 0 auto;
    }
  }
}
