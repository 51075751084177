@import "../../core/styles/colors.scss";

.inputText {
  .MuiFormControl-root {
    margin: 15px 20px 15px 0;
    &:hover {
      .MuiInputLabel-root {
        color: $text;
      }
    }
    .MuiInputLabel-root {
      font-family: "Circular-Std", Arial, sans-serif;
      font-size: 0.9rem;
      line-height: inherit;
      .MuiInputLabel-asterisk {
        color: $red_alert;
      }
    }

    .MuiOutlinedInput-root {
      background: $gray_light-2;
      border-radius: 10px;
      &:hover {
        fieldset {
          border-color: $gray_strong;
          
        }
      }
      .MuiInputBase-input {
        padding: 16.5px 15px;
        height: 15px;
        font-size: 14px;
        background: $gray_light-2;
        border-radius: 10px;
      }
      .MuiSvgIcon-root {
        fill: $green;
      }
    }

    fieldset {
      border-color: $gray_medium;
      legend {
        max-width: 0;
      }
    }
    // when focused
    .Mui-focused,
    .MuiFormLabel-filled {
      &.MuiInputLabel-root {
        color: $blue_petrol_strong !important;
        top: -8px;
      }

      fieldset {
        border-width: 1px !important;
        border-color: $blue_petrol_strong !important;
        legend {
          max-width: 0;
        }
      }
    }
    // Error
    .Mui-error {
      color: $red_alert;
      &.MuiInputLabel-root {
        color: $red_alert !important;
      }
      &.MuiOutlinedInput-root {
        fieldset {
          border-color: $red_alert !important;
        }
      }
    }
  }
}

.MuiCalendarPicker-root {
  .MuiPickersDay-root.Mui-selected, .PrivatePickersYear-yearButton.Mui-selected  {
    background-color: $blue !important;
  }
  .PrivatePickersSlideTransition-root {
    min-height:200px;
  }
}
  
