@import "./fonts.scss";
@import "./colors.scss";
@import "./mixins.scss";

// Title
body {
  margin: 0;
  padding:0 !important;
  overflow-x: hidden;
  * {
    font-family: "Circular-Std-Book", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $text;
    h1 {
      font-family: "Circular-Std", Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 35px;
      line-height: 110%;
      color: $green;
    }
    h2 {
      font-family: "Circular-Std", Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 32px;
      &.green {
        color: $green;
      }
      &.smMargin {
        margin: 8px 0 0px;
      }
    }
    h3 {
      font-family: "Circular-Std", Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 146%;
      /* or 23px */
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }
    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      /* or 15px */
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }
    a {
      font-weight: 400;
      color: $green;
      cursor: pointer;
      &:hover {
        color: $green_light;
      }
    }
    .bold {
      font-family: "Circular-Std", Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
    }
    span {
      &.green {
        color: $green;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: rgb(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $green;
  border-radius: 10px;
}
