@import "../../../core/styles/colors.scss";

.smallingrey {
  font-size: 14px;
  color: $gray_medium;
  margin: 0 0 4px;
}

.smallmarginbottom {
  margin-bottom: 0;
}

.smallmargin {
  margin: 4px 0 6px;
}

.smallmargin0bot {
  margin: 4px 0 0;
}

.smallmargin0top {
  margin: 0 0 6px;
}

.marginbottom12 {
  margin-bottom: 12px;
}

.small {
  font-size: 12px;
  margin: 4px 0 12px;
}

.details {
  display: flex;
  justify-content: space-between;
  p {
    position: relative;
    margin-top: 0;
    margin-bottom: 20px;
    svg {
      margin-right: 13.5px;
      path {
        fill: $blue;
      }
    }
  }
}
.bloc_recapPage {
  margin-bottom: 80px;
}
