@import "../../core/styles/colors.scss";
@import "../../core/styles/mixins.scss";

.container_bloc {
   margin-bottom: 12px;
   @include shadow;
   border-radius: 8px;
   header {
      padding: 12px 20px;
      background: $green;
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.notCheck {
         background: $green_light;
      }
      &.isOpen {
         background: $green;
         border-bottom-left-radius: 0px;
         border-bottom-right-radius: 0px;
      }

      h2 {
         color: $white;
         margin: 0 12px;
      }
      .header_start {
         display: flex;
         align-items: center;
         justify-content: center;
      }
      .header_end {
         color: $white;
         svg {
            margin-left: 20px;
         }
      }
   }
   main {
      background: $white;
      padding: 36px 30px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .bloc_note {
         border: none;
         border-radius: 8px;
         padding: 12px 8px;
         background-color: $gray_light;
         margin-bottom: 15px;
         .note_title {
            margin: 0 0 5px;
         }
         p {
            margin: 0;
            font-size: 14px;
         }
      }
   }
}
