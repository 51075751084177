@import "../../core/styles/colors.scss";

.deleteButton-container{
    display: flex;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    .deleteButton {
       border-radius: 50%;
       width:36px;
       height:37px;
       padding: 0;
       svg {
        path {
            fill: $text;
        }
       }
    }
}
