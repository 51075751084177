@import "../../core/styles/colors.scss";

.barreButtonList {
   margin-top: 20px;
   display: flex;
   input {
      display: none;
   }

   label {
      button {
         border-left: none;
         border-right: none;
         border-radius: 4px;
         margin-left: -1px;
      }
      &:first-of-type:last-of-type {
         button {
            border-left: 1px solid $green;
            border-right: 1px solid $green;
            border-radius: 4px !important;
            // border-left: none !important;
         }
      }
      &:first-of-type {
         button {
            border-left: 1px solid $green;
            border-right: 1px solid $green;
            margin-right: 0px;
            margin-left: 0;
            border-radius: 4px !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
         }
      }
      &:last-of-type {
         button {
            border-left: 1px solid $green;
            border-right: 1px solid $green;
            border-radius: 4px !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            // border-left: none !important;
         }
      }
   }
   &.powerPDL {
      label {
         &:last-of-type {
            button {
               cursor: default;
               border-color: $gray_medium;
               color: $gray_medium;
            }
         }
      }
   }
}

.barreButtonList_error {
   color: $red_alert;
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   font-weight: 400;
   font-size: 0.75rem;
   line-height: 1.66;
   letter-spacing: 0.03333em;
   text-align: left;
   margin-top: 3px;
   margin-right: 14px;
   margin-bottom: 0;
   margin-left: 14px;
}
