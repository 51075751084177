@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.popin {
  .page_content_valid {
    .container {
        header {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
            margin-top: 20px;
            margin-bottom: 5px;
            flex-direction: column;
            .idLabel {
                margin-top: 20px;
            }           
        }
        .main_container_valid {
            .title_line {
                color: $blue_petrol_strong;
                font-weight: 500;
                margin-top: 15px;
                margin-bottom: 10px;
                
            }
            .container_combobox .MuiFormControl-root {
                width: 100%;
            }
            .btn_container {
                display: flex;
                justify-content:center;
                margin-top: 15px;
                button {
                    &:first-of-type {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
  }
}