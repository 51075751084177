@import "../../../../core/styles/colors.scss";

.recharts-wrapper {
   margin: 0 auto;
}

.recharts-legend-wrapper {
   // width: unset !important;
   // left: 50px !important;
   // bottom: 30px !important;
   // position: inherit !important;
}

span.recharts-legend-item-text {
   color: black !important;
   margin-right: 10px;
   padding: 5px;
}

li.recharts-legend-item.legend-item-1,
li.recharts-legend-item.legend-item-2 {
   height: 30px;
}

.nodata {
   opacity: 0;
   transition: opacity 0.2s 0.2s ease;
   &.active {
      opacity: 0.5;
   }
}

.show-data-button {
   color: $blue;
}