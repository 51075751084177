@import "../../../core/styles/colors.scss";

.customRadio_wrapper {
    display: inline-flex;
   
    
    .customRadio {
        
        cursor: pointer;
        width: 0;
        height: 0;
        position: relative;

        &::after, &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 2px solid $gray_medium;
        z-index: 1;
        
        }
        
        &::before {
        width: 8px;
        height: 8px;
        left: 0.19em;
        top: 0.2em;
        border-color: transparent;
        }
        
        &:checked::before {
        border-color: $green;
        background-color: $green;
        }
        &:checked::after {
        border-color: $green;
        }
        
    }
    // &:first-of-type {
    //     .customRadio {
    //         &::before {
    //             left: 3.81px;
    //         }
    //     }
       
    // }
    .customRadio_cache {
        position: absolute;
        width: 22px;
        height: 22px;
        background: $white;
    }
    label {
        margin-left: 23px;
        margin-top: 4px;
        cursor: pointer;
    }
    input[type="radio"] {
        appearance: none;
        
    }
}