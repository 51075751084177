@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.buttonChart {
   box-sizing: border-box;
   width: 165px;
   height: 50px;
   margin-right: 12px;
   padding: 15px 7.5px 15px;
   vertical-align: middle;
   border-radius: 160px;
   border: 1px solid black;
   font-size: 12px;
   display: flex;
   align-items: center;
   p {
      margin: 0 5px -2px;
   }
   .icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
   }
}
