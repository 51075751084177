@import '../../core/styles/colors.scss';

.container_inputSms {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    .input_wrapper {
      
       position: relative;
       flex-direction: row;
       display: flex;
       &.notCompleted {
           .display {
            border-color: $red_alert;
           }
       }
       .display {
            border: 1px solid $blue;
            border-radius: 4px;
            width: 34px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow:visible;
            position: relative;
            &:nth-of-type(n+2) {
                margin-left: 9px;
            }
            &.error {
                border-color: $red_alert;
            }
        }
        .input {
            position: absolute;
            border: none;
            text-align: center;
            background: transparent;
            outline: none;
            width: 34px;
            top: 0px;
            bottom: 0px;
            left: 0px;
        }
        .shadows {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 4px;
            box-shadow: 0 0 0 4px rgba(58, 151, 212, 0.28);
        } 

    }
}