@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.popin {
    background-color: white;
    width: 700px;
    max-height: 85%;
    padding: 40px 60px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    border-radius: 10px;
    @include shadow;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    .page_content_addsociete {
        position: relative;
       
        .main_container_adduser {
            .container_header {
                margin-bottom: 20px;
            }
            .headerTab {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0;
                margin-top: 20px;
                margin-bottom: 5px;
                background: $gray_light-2;
                &.true {
                    border-bottom: 3px solid $blue;
                    background: transparent;
                }
                &.false {
                    background: $gray_light-2;
                }
                // &.noActive {
                //   
                // }
                &:first-of-type {
                    margin-right: 15px;
                }
                &:last-of-type {
                    margin-left: 15px;
                }
            }
        }
        .container_data, .container_remuneration {
            
            
            &.true {
                display: flex;
            }
            &.false {
                display: none;
            }
            .container_combobox .MuiFormControl-root .MuiFormHelperText-root.MuiFormHelperText-root {
                position: absolute;
                bottom: -20px;
            }
        }
        .container_data {
            .container_adresse {
                margin-top: 0 !important;
                margin-bottom: 28px !important;
            }
        }
        .container_remuneration {
            .title_line {
                h4 {
                    color: $blue;
                    font-weight: 500;
                    margin-top: 5px;
                }
            }
            .title_col_line {
                margin-bottom: 20px;
                text-align: center;
                div:first-of-type {
                    padding-left: 42px;
                }
                &.title_3c {
                    div:first-of-type {
                        padding-left: 0;
                    } 
                }
            }
            .line_gray {
                background: $gray_light-2;
                border-radius: 10px;
                padding: 8px 42px;
                align-items: center;
                margin-bottom: 16px;
                div {
                    padding-top: 0;
                }
            }

            .containe_bloc_puissance {
                .line_gray {
                    &:nth-of-type(3n+2) {
                      margin-left: 8px;
                      margin-right: 8px;
                    }
                }
                .bloc_puissance {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    p {
                        margin-right: 12px;
                    }
                }
            }
        }
        
    }
   
  }
  .popinAddSocietes .cross {
    top: 8px;
  }