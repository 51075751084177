@import "../../../../../core/styles/colors.scss";
@import "../../../../../core/styles/mixins.scss";

.pagination_wrapper {
    display: flex;
    margin: 1vh 0 0 0;
    padding: 0 25px ;
    .pagination_container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .pagination_nav {
            ul {
                display: flex;
                border-radius: 5px;
                padding: 0;
                li {
                    button {
                        background: $green;
                        border-radius:0;
                        color: $white;
                        align-items: baseline;
                        width: 36px;
                        &:hover {
                            background: $green_light;
                        }
                        &.selected {
                            background: $green_strong;
                        }
                    }
                    .prevBtn {
                        padding: 15px 12px 12px;
                        width: 94px;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        svg {
                            margin-right: 5px;
                        }
                    }
                    .nextBtn {
                        padding: 15px 12px 12px;
                        width: 94px;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        svg {
                            margin-left: 5px;
                        }
                    }
                    .ellipsis {
                        width: 36px;
                        display: flex;
                        justify-content:center;
                        align-items:baseline;
                        padding: 13.5px 0;
                        color: $white;
                        background: $green;
                        
                    }
                    
                }
            }
    
        }
        .pagination_row {
            width: 45%;
            display: flex;
            justify-content: flex-end;
            .MuiTablePagination-displayedRows, .MuiTablePagination-actions {
                display:none;
            }
            .MuiTablePagination-toolbar {
                border: 1px solid $text;
                border-radius: 63px;
                min-height: 45px;
                .MuiTablePagination-selectLabel {
                    margin-top: 11px;
                    margin-bottom: 9px;
                    font-family: "Circular-Std-book", Arial, sans-serif;
                    text-transform: uppercase;
                    line-height:1em;
                    cursor: default;
                }
                .MuiSelect-select {
                    font-family: "Circular-Std-book", Arial, sans-serif;
                    font-size: 14px;
                    padding-left: 5px;
                    padding-right: 8px;
                    text-align: left;
                    -webkit-text-align-last: left;
                    text-align-last: left;
                    min-width: 35px;
                    z-index: 1;
                    &:focus {
                        background: transparent;
                    }
                } 
                .MuiInputBase-root {
                    margin-right: 5px;
                    margin-top: 2px;
                    margin-left: 0;
                    svg {
                        width: 1.7em;
                        height: 0.7em;
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }
    }
}
