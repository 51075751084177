@import '../../core/styles/colors.scss';

.radioButton_container {
    .radioButton_container_inputs{
        display: flex;
        flex-direction: row;
        justify-content: center;
        label {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            position: relative;
            input {
                display: none;
                
            }
            .radio-point {
                width: 32px;
                height: 32px;
                border: 2px solid $gray_medium;
                border-radius: 100%;
                position: relative;
                margin-bottom: 12px;
                &::before {
                    content: "";
                    display: block;
                    width: inherit;
                    height: inherit;
                    border-radius: inherit;
                    position: absolute;
                    transform: scale(0);
                    transform-origin: center center;
                    opacity: 0;
                    transition: .3s;
                    background: $green;
                }
            }
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: $gray_medium;
            
            }
            &:nth-of-type(2) {
                margin: 0 94px;
            }
        }
        .checked {
            .radio-point {
                border-color: $green;
                &::before {
                    opacity: 1;
                    transform: scale(.7);
                } 
            }
            span {
                color:$green;
            }
        }
    }
    .message-item {
        display: flex;
        justify-content:center;
        margin-top: 24px;
        background: $red_alert_10;
        border-radius: 4px;
        padding: 14px 20px;
    }
}