@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.popin {
  background-color: white;
  width: 680px;
  max-height: 500px;
  padding: 50px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  border-radius: 10px;
  @include shadow;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  .barreButton {
    margin-top: 0;
  }
}

.page_content_adduser {
  position: relative;
}

.title {
  margin: 19px 0 15px 0 !important;
  color: $green;
}

.subtitle {
  margin: 0;
  color: $blue;
}

#label-radio-group-user-type {
  font-weight: 500;
}

.error_wrapper {
  border: 1px solid $red_alert;
  border-radius: 4px;
}

.type_error {
  color: $red_alert;
  top: 48px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 0px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.main_container_adduser {
  margin-top: 15px;
}

.popin_adduser_header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.radio_controller_container {
  max-height: 48px;
  padding-left: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.radio_group_container {
  display: flex;
  justify-content: space-evenly;
  min-width: 90%;
  margin-left: 15px;
  max-height: 48px;
}

.visuCommission_container {
  display: flex;
  align-items: baseline;
  margin: 10px 0;
}

.cross {
  position: absolute;
  top: 32px;
  right: 50px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  
  path {
    color: $text;
    fill: $text;
  }
}

.save {
  margin: 25px auto 0;
}

@media only screen and (max-width : 1460px){
  .radio_group_container {
    min-width:87%;
  }
  #label-radio-group-user-type {
    width: 13%;
  }
}

.popinaddUser .cross {
  top:33px;
}
