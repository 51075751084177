@font-face {
    font-family: 'Circular-Std';
    src: 
        url('../assets/fonts/CircularStd-Bold.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-Bold.woff') format('woff'),
        url('../assets/fonts/CircularStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Circular-Std-Book';
    src: 
        url('../assets/fonts/CircularStd-Book.woff2') format('woff2'),
        url('../assets/fonts/CircularStd-Book.woff') format('woff'),
        url('../assets/fonts/CircularStd-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }