@import '../../core/styles/colors.scss';
@import '../../core/styles/mixins.scss';

.container_confirmSub {
    bottom: 0;
    width: 100%;
    z-index: 10;
    position: sticky;
    @include shadow;
    background: $white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .arrow-corner {
        position: absolute;
        right: 22px;
        top: 22px;
        svg {
            path {
                fill: $text;
            }
        }
    }
    header {
        cursor: pointer;
        z-index: 1;
        position: relative;
        padding: 25px 0 15px;
        h2 {
            margin: 0;
            padding: 0 30px;
        }
    }
    main {
        padding: 0 30px;
        .bold { 
            font-family: "Circular-Std", Arial, sans-serif;
            font-weight: 700;
        }
        .blue {
            color: $blue;
        }
        .subTitle {
            font-size: 12px;
            color: $gray_medium;
            margin-top: 0;
        }
        
        .container_lineSms {
            margin-top: 20px;
            padding-bottom: 30px;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            p {
                margin: 0;
            }
            .text_bloc {
                h3 {
                    margin-top: 0;
                    margin-bottom: 5px;
                }
                .lien {
                    color: $blue;
                    &:before {
                        background: $blue;
                    }
                    &.disabled {
                        cursor: default;
                        color: $gray_medium;
                        &:before {
                            background: $gray_medium;
                        }
                    }
                }
            }
            .validation_code {
                display: flex;
                position: relative;
                .error_message {
                    color: $red_alert;
                    font-size: 0.75em;
                    position: absolute;
                    bottom: -10px;
                }
            }
        }
        .wrappe_btn {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
        }
    }
}