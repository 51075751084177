@import '../../core/styles/colors.scss';
@import '../../core/styles/mixins.scss';

.container_resume {
    bottom: 0;
    width: 680px;
    z-index: 10;
    &.show {
        opacity: 1;
        position: fixed;
        display: block;
    }
    @include shadow;
    display: none;
    opacity: 0;
    flex-direction: column;
    text-align: center;
    position: relative;
    background: $white;
   
    padding: 6px 0 22px 0;
    border-radius: 8px;

    .arrow-corner {
        position: absolute;
        right: 22px;
        top: 22px;
        svg {
            path {
                fill: $text;
            }
        }
    }
    header {
        cursor: pointer;
        z-index: 1;
        position: relative;
        h3 {
            margin: 0;
            padding: 1em 0;
        }
    }
    main {
        padding:0 112px;
        h3 {
            margin-top: 32px;
        }
    }
    .line-values {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // padding-bottom: 16px;
        div {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: flex-start;
            h2 {
                color: $green;
                margin: 0 3px 0 0;
            }
            &:nth-of-type(2) {
                h2 {
                    margin-left: 3px;
                }
            }
        }
    }

    .line-valide {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 4px;
    }

}