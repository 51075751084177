@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.page_content_accueil {
  background: $blue_accueil;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  .container_white {
    background: $white;
    @include shadow;
    border-radius: 8px;
    display: flex;
    padding: 60px 75px;
    width: 500px;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    position: relative;
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      padding: 0 54px;
      margin-bottom: 60px;
    }
    .logoSmall-bg {
      position: absolute;
      bottom: -210px;
      right: -25px;
    }
    .container_btn {
      display: flex;
      flex-direction: column;
      button {
        &:first-of-type {
          margin-bottom: 20px;
        }
      }
    }
  }
  .logoBig-bg {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.modal {
  background: $white;
  @include shadow;
  border-radius: 8px;
  display: flex;
  padding: 30px 50px;
  width: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  flex-direction: column;
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      &:first-of-type {
        margin-right: 15px;
      }
    }
  }
  z-index: 10;
}
