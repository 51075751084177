@import "../../../core/styles/colors.scss";

.container_adresse {
  .MuiFormControl-root {
    margin: 15px 0 15px 0;
    &:hover {
      .MuiInputLabel-root {
        color: $text;
      }
    }
    .MuiOutlinedInput-root {
      
    }
    .MuiOutlinedInput-root {
      border-radius: 10px;
      background: $gray_light-2;
      padding: 0;
      &:hover {
        fieldset {
          border-color: $gray_strong;
        }
      }
      .MuiInputBase-input {
        padding: 14px 15px;
        font-size: 14px;
        background: $gray_light-2;
        border-radius: 10px;
      }
    }
    .MuiInputLabel-root {
      font-family: "Circular-Std", Arial, sans-serif;
      font-size: 0.9rem;
      line-height: inherit;
      top: 0;
      .MuiInputLabel-asterisk {
        color: $red_alert;
      }
    }

    fieldset {
      border-color: $gray_medium;
      legend {
        max-width: 0;
      }
    }
    // when focused
    .Mui-focused,
    .MuiFormLabel-filled {
      &.MuiInputLabel-root {
        color: $blue_petrol_strong !important;
        top: -8px;
      }

      fieldset {
        border-width: 1px !important;
        border-color: $blue_petrol_strong !important;
        legend {
          max-width: 0;
        }
      }
    }
    // Error
    .Mui-error,
    .MuiFormHelperText-root {
      color: $text;
      &.MuiInputLabel-root {
        color: $red_alert !important;
      }
      &.MuiOutlinedInput-root {
        fieldset {
          border-color: $red_alert !important;
        }
      }
      &.MuiFormHelperText-root {
        color: $red_alert;
      }
    }
  }
}

// overwrite modal select
#menu- .MuiPaper-elevation1 {
  max-width: 280px !important;
  width: 100%;
  border-radius: 8px;
  margin-top: 10px;
  .MuiMenuItem-root {
    font-family: "Circular-Std-Book", Arial, sans-serif !important;
    .MuiTouchRipple-root {
      display: none;
    }
    &:hover {
      background: $blue;
      color: $white;
    }
  }
  .Mui-selected {
    background: $blue;
    color: $white;
  }
  .Mui-focusVisible {
    background: $blue;
    color: $white;
  }
}
