@import '../../../core/styles/colors.scss';

.bloc_payment {
    h3 {
        // margin: 0 0 10px 0;
    }
    .input-text {
        .MuiFormControl-root {
            margin-bottom: 0;
        }
    }
    .legalText {
        margin-top: 15px;
        margin-bottom: 20px;
    }
    .checkboxButton {
        flex-direction:column;
        padding-left: 0;
        position: relative;
        width: 85%;
        .helpText {
            position: absolute;
            bottom: -25px;
            left: 15px;
            .MuiFormHelperText-root {
                color: $red_alert;
            }
        }
    }
}

