@import '../../../core/styles/colors.scss';

.bloc_id {
    .checkboxButton {
        flex-direction:column;
        padding-left: 0;
        position: relative;
        width: 85%;
        .helpText {
            position: absolute;
            bottom: -25px;
            left: 15px;
            .MuiFormHelperText-root {
                color: $red_alert;
            }
        }
    }
}
