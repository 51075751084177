@import '../../../core//styles//colors.scss';

.bloc_offer {
    .bloc_offer-detail {
        h3 {
            margin-top: 0;
        }
    }
    // .barrebutton-offer {
    //     width: 100%;

    //     label {
    //         &:first-of-type {
    //             width: 51.5%;
    //         }
    //         &:last-of-type {
    //            button {
    //             border-left: 1px solid $green;
    //            }
    //         }
    //         width: 50%;
    //         button {
    //            border-radius: 4px !important;
            
            
    //         }
    //     }
    // }
    .bloc_txt-details {
        max-height: 75px;
        overflow: hidden;
        margin-bottom: 8px;
        &.open {
            max-height: 100%;
        }
    }
    .option-tarif-container {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        span {
            display: flex;
            align-items: center;
            h3 {
                margin-right: 15px;
                margin-bottom: 14px;
            }
        }
        .barreButton {
            margin-top: 0;
        }
        .price-subsciption-container {
           display: flex;
           flex-direction: column;
           .line-subsciption, .line-base, .line-heurePleine, .line-heureCreuse {
               display: flex;
               flex-direction:row;
               justify-content: space-between;
               p {
                   margin-bottom: 0;
               }
           } 
        }
       
    }
    .offer-container {
        display: flex;
        flex-direction: column;
        .line-options {
            display: flex;
            flex-direction: column;
            .checkboxButton {
                padding-left:0;
                padding-top: 0;
                padding-right: 0;
                padding-bottom: 2px;
                justify-content: space-between;
                &:last-of-type {
                    padding-bottom: 0;
                }
                label .MuiCheckbox-root {
                    padding-left: 0;
                }
                label {
                    display: flex;
                    
                    align-items: center;
                    span {
                        display: flex;
                       
                    }
                }
                .line-option-checkbox {
                    display: flex;
                    align-items: center;
                    div {
                        display: flex;
                        align-items: baseline;
                    }
                    h2 {
                        margin: 0 5px 0 0;

                    }
                    p {
                        margin: 0;
                    }
                }
            }
            .premium-option {
                display: flex;
                justify-content: space-between;
                p {
                    position: relative;
                    margin-top: 0;
                    svg {
                        margin-right: 5px;
                        path {
                            fill: $blue;
                        }
                        
                    }
                }
                &:last-of-type {
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
   
}