@import '../../../../../core/styles/colors.scss';
@import '../../../../../core/styles/mixins.scss';

.error-block {
    margin: 0 auto;
    border-radius: 10px;
    @include shadow;
    padding: 28px;
    background-color: $white;
    h3 {
        text-transform: none;
        font-size: 25px;
        margin-bottom: 15px;
    }
    .btn {
        margin: 20px auto 0;
        width: 100%;
    }
}

