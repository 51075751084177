@import "../../../core/styles/colors.scss";
@import "../../../core/styles/mixins.scss";

.home {
  position: absolute;
  top: 0;
  z-index: 2;
}

.header_container {
  width: 680px;
  @include shadow;
  display: flex;
  height: 70px;
  position: relative;
  background: #ffffff;
  z-index: 2;
  border-radius: 0px 0px 8px 8px;

  .logoPrimeo {
    position: absolute;
    width: 98.51px;
    height: 34px;
    left: 30px;
    top: 18px;
  }

  .children {
    position: absolute;
    right: 30px;
    top: 10px;
    button.blue.outline {
      line-height:0.4;
      margin-top: 7px;
    }
  }
}


@media only screen and (min-width : 1460px){
  .home {
    width: 90%;
  }
}
