
.header_BOCO_container {
  margin-bottom: 60px;
}

.main_BOCO_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 25px;
}

.main_BOCO_content {
  transition: ease .3s .1s;
  padding: 0 28px;
}
