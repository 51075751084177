@import "../../../../../core/styles/colors.scss";
@import "../../../../../core/styles/mixins.scss";

.table_container {
  white-space: nowrap;
  .MuiTableContainer-root {
    overflow-x: visible;
  }
  .input-text .MuiFormControl-root {
    margin: 0;
    .MuiOutlinedInput-root .MuiInputBase-input {
      padding: 12px;
    }
  }

  

  .container_combobox
    .MuiFormControl-root
    .MuiOutlinedInput-root
    .MuiInputBase-input {
    padding: 8px 15px !important;
    background: transparent;
  

  }
  .container_combobox .MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled {
    fieldset {
      border-color: rgba(0, 0, 0, 0) !important;
    }
    svg {
      opacity: 0.5;
    }
    &:hover {
      fieldset {
        border-color: rgba(0, 0, 0, 0) !important;
      }
    }
  }




  tr {
    height: 48px;
    background-color: $gray_light-2;
    cursor: pointer;
    &:hover {
      background-color: $green_line;
    }
    
      .MuiCheckbox-root.Mui-checked, .MuiCheckbox-root.MuiCheckbox-indeterminate  {
        path {
          color: #0abbf0;
          fill: #0abbf0;
        }
      }
    &.Mui-selected {
      background-color: $gray_light-2;
      td {
        border-bottom: 1px solid $blue;
        border-top: 1px solid $blue;
        &:first-child {
          border-left: 1px solid $blue;
        }
        &:last-child {
          border-right: 1px solid $blue;
        }
      }
     
    }
  }

  thead tr {
    background-color: rgba(255, 255, 255, 0);
    padding: 10px 0 !important;
    &:hover {
      background-color: rgba(255, 255, 255, 0);
    }
    .MuiTableCell-head {
      .MuiTouchRipple-root {
        display: none;
      }
      .MuiCheckbox-root{
        &:hover {
          background-color: transparent;
        }
      }
      .MuiCheckbox-root.Mui-checked, .MuiCheckbox-root.MuiCheckbox-indeterminate  {
        path {
          color: #0abbf0;
          fill: #0abbf0;
        }
      }
    }
    .MuiTableCell-head {
      border-bottom: none;
      .MuiButtonBase-root.MuiTableSortLabel-root {
        font-weight: 900;
        color: $gray_medium;
        font-size: 14px;
        svg {
          opacity: 1;
          height: 15px;
          path {
            fill: $gray_medium;
            color: $gray_medium;
          }
        }
      }
    }
  }

  td {
   border-top: 1px solid transparent;
   border-bottom: 1px solid transparent;
    padding-right: 30px;
    p {
      padding: 0;
      margin: 0;
      font-size: 14px;
    }
  }
  td:first-child {
    border-left: 1px solid transparent;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-right: 5px;
    .MuiTouchRipple-root {
      display: none;
    }
    .MuiCheckbox-root{
      &:hover {
        background-color: transparent;
      }
    }
  }
  td:last-child {
    border-right: 1px solid transparent;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    padding-right: 0px;
  }
}

.custom-table-row {
  &.edit, &.edit.Mui-selected {
    td {
      border: solid 2px $green;
      background-color: #e8f0ee;
      border-style: solid none;
      p {
        padding: 0;
        margin: 0;
      }
    }
    td:first-child {
      border-left: 2px solid $green;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    td:last-child {
      border-right: 2px solid $green;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      padding-right: 0px;
    }
    td:nth-last-of-type(2) {
      padding-right: 12px;
    }
  }
  .modif_icon {
    opacity: 0;
    transition: ease-out 0.25s;
  }
  &:hover {
    .modif_icon {
      opacity: 1;
    }
  }
}

.custom-table-row .custom-cell {
  div {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    height: inherit;
    svg:first-child {
      margin-right: 5px;
    }
  }
}
