@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.dash_container {
   height: 100%;
   background-color: $white;
   border-radius: 10px;
   // position: relative;
   overflow: hidden;
   @include shadow;
}

.dash_header {
   padding: 28px 28px 12px;
   position: sticky;
   top: 0;
   left: 0;
   background: white;
   z-index: 1000;

   .inputText .MuiFormControl-root .MuiOutlinedInput-root,
   .container_combobox_multi .MuiFormControl-root .MuiOutlinedInput-root {
      height: 33px;
   }

   .inputText {
      label {
         top: -7px;
      }
   }

   .container_combobox_multi .MuiFormControl-root {
      label {
         top: 0px;
      }
   }
}

.dash_header_filters {
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   background-color: $gray_light-2;
   border-radius: 10px;
   padding: 10px;
   margin-top: 15px;
   //    margin-bottom: 20px;
   div {
      .container_combobox_multi .MuiFormControl-root {
         min-width:150px;
      }
   }
   .blue {
      padding: 6px 19px 6px;
      font-size: 14px;
      border: 1px solid $blue !important;
      line-height: 1;
      margin-top: 15px;
   }

   .inputText {
      max-width: 160px;
      margin-right: 8px;
   }

   .container_combobox {
      margin-right: 8px;
      .MuiFormControl-root {
         min-width: 180px;
         .MuiInputLabel-root {
            top: 0;
            &.MuiFormLabel-filled,
            &.Mui-focused {
               top: -8px;
            }
         }
         .MuiOutlinedInput-root .MuiInputBase-input {
            margin-right: 20px;
            padding: 5px 10px;
         }
      }
   }
   .container_combobox_multi {
      margin-right: 8px;
   }
}

.dash_content_wrapper {
   padding: 28px;
   // overflow-y: scroll;
   // overflow-x: hidden;
   // position: relative;
   //    ::-webkit-scrollbar {
   //       width: 6px;
   //       height: 6px;
   //       background: rgb(0, 0, 0, 0.1);
   //       border-radius: 10px;
   //    }
   //    ::-webkit-scrollbar-thumb {
   //       background: $green;
   //       border-radius: 10px;
   //    }
}

.dash_content {
   padding: 0 12px 0 0;
   width: inherit;
   position: static;
   background-color: $gray_light-2;
   border-radius: 5px;

   .dash_content_head {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      .chart_setter {
         display: flex;
         justify-content: space-between;
         align-items: center;
         button {
            height: 30px;
            margin: 4.5px;
            vertical-align: middle;
            border-radius: 160px;
            border: 1px solid black;
            font-size: 12px;
            &.blue {
               border: none;
            }
         }
         @media only screen and (min-width: 768px) {
            button {
               height: 38px;
            }
         }
      }
   }
}

.selectChart {
   width: 165px;
   .MuiSelect-select {
      background-color: $blue;
      color: white;
      border-radius: 160px !important;
      padding: 13.5px 25px;
      padding-right: 36px;
      margin-bottom: -2px;
   }
   .MuiOutlinedInput-root {
      border: 1px solid red;
   }
   fieldset {
      border: none;
   }
   .MuiSvgIcon-root {
      fill: white;
   }
}
