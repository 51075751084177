@import "../../core/styles/colors.scss";

.input-text {
   &.area {
      .MuiFormControl-root {
         .MuiOutlinedInput-root {
            .MuiInputBase-input {
               padding: 0;
               border-radius: 0;
            }
         }
      }
   }
   .MuiFormControl-root {
      margin: 15px 0 15px 0;
      &:hover {
         .MuiInputLabel-root {
            color: $text;
         }
      }

      .MuiInputLabel-root {
         font-family: "Circular-Std", Arial, sans-serif;
         font-size: 0.9rem;
         line-height: inherit;
         .MuiInputLabel-asterisk {
            color: $red_alert;
         }
      }

      .MuiOutlinedInput-root {
         background: $gray_light-2;
         border-radius: 10px;
         &:hover {
            fieldset {
               border-color: $gray_strong;
            }
         }
         .MuiInputBase-input {
            padding: 16.5px 15px;
            height: 15px;
            font-size: 14px;
            background: $gray_light-2;
            font-family: "Circular-Std-book", Arial, sans-serif;
            border-radius: 10px;
         }
         &.Mui-disabled {
            fieldset {
               border-color: $gray_light-2;
            }
            &:hover {
               fieldset {
                  border-color: $gray_light-2;
               }
            }
         }
         .endAdornmentHT {
            display: flex;
            width: 50px;
            justify-content: flex-end;
         }
      }

      fieldset {
         border-color: $gray_medium;
         legend {
            max-width: 0;
         }
      }
      // when focused
      .Mui-focused,
      .MuiFormLabel-filled {
         &.MuiInputLabel-root {
            color: $blue_petrol_strong !important;
            top: -8px;
         }

         fieldset {
            border-width: 1px !important;
            border-color: $blue_petrol_strong !important;
            legend {
               max-width: 0;
            }
         }
      }
      // Error
      .Mui-error {
         color: $text;
         &.MuiInputLabel-root {
            color: $red_alert !important;
         }
         &.MuiOutlinedInput-root {
            fieldset {
               border-color: $red_alert !important;
            }
         }
         &.MuiFormHelperText-root {
            color: $red_alert;
            position: absolute;
            top: 47px;
            line-height: 1;
            letter-spacing: 0;
            width: 100%;
            font-family: "Circular-Std-book", Arial, sans-serif;
         }
      }
   }
   // Delete up down btn in input number
   /* Chrome, Safari, Edge, Opera */
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   /* Firefox */
   input[type="number"] {
      -moz-appearance: textfield;
   }
}
