@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

html {
  background: $gray_light-2;
}

.page_content_recap {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .container {
    // padding: 0 96px;
    width: 680px;
    margin: 0 auto;
    .bloc_recapPage {
      .container_option {
        position: relative;
      }
      .green {
        color: $green;
      }
      .bold {
        font-weight: bold;
        margin-bottom: 0 !important;
        line-height: 0;
        margin-top: 10px;
      }
  
      .mleft {
        margin-left: 30px;
        margin-bottom: 0 !important;
        line-height: 0;
      }

      .years {
        margin-bottom: 30px;
      }
  
      .line {
        border-bottom: 1px solid $green;
        position: absolute;
        content: "";
        width: 97%;
        opacity: 0.25;
      }
  
      .fieldset {
        border: 1px solid $green;
        padding: 30px;
        width: 100%;
        margin-left: 24px;
      }
  
      

    }
    .arrow {
      transform: rotate(180deg);
      path {
        fill: $blue;
      }
  }
  }
}

@media only screen and (min-width : 1460px){

  .page_content_recap {
    .container {
      padding: 0 0 60px;
      width: 96%;
      .main_container {
        width: 1400px;
        margin: 0 auto;
        .wrapper {
          display: flex;
          .bloc_recapPage {
            width: 870px;
          }
          .container_confirmSub {
            max-width: 480px;
            width: auto;
            margin-left: 30px; 
            height: fit-content;
            position: sticky;
            align-self: flex-start;
            top: 15%;
            overflow-y: auto;
            header {
              cursor: default;
            }
            .container_lineSms {
              flex-direction: column;
              .text_bloc {
                margin-bottom: 20px;
              }
              .validation_code {
                flex-direction: column;
                .container_inputSms {
                  justify-content: flex-start;
                  margin-bottom: 20px;
                }
                .error_message {
                  bottom: 20px;
                }
                .lien{
                  color: $blue;
                  &:before {
                    background: $blue;
                  }
                  &.disabled {
                    cursor: default;
                    color: $gray_medium;
                    &:before {
                      background: $gray_medium;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
