@import "../../../core/styles/colors.scss";

.container_combobox_multi {
  .MuiFormControl-root {
    margin: 15px 0 15px 0;
    width: 100%;
    &:hover {
      .MuiInputLabel-root {
        color: $text;
      }
    }
    .MuiOutlinedInput-root {
      background: $gray_light-2;
      border-radius: 10px;
      &:hover {
        fieldset {
          border-color: $gray_strong;
        }
      }
      .MuiInputBase-input {
        padding: 12px 15px;
        font-size: 14px;
        background: $gray_light-2;
      }
    }
    .MuiInputLabel-root {
      font-family: "Circular-Std", Arial, sans-serif;
      font-size: 0.9rem;
      line-height: inherit;
      top: 6px;
      .MuiInputLabel-asterisk {
        color: $red_alert;
      }
    }

    fieldset {
      border-color: $gray_medium;
      legend {
        max-width: 0;
      }
    }

    .wrapper_tag {
      display: flex;
      flex-direction: row;
      position: relative;
    }

    .tagSelectRegion, .tagSelectDepartement{
      cursor: pointer;
      background: $blue;
     
      border-radius:5px;
      
      padding: 0 14px;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-right: 10px;
      p {
        color: $white;
        font-size: 14px;
        margin: 0;
      }
      .cross {
        display: flex;
        margin-right: 5px;
       svg {
        color: $white;
       }
      }
    }
    // when focused
    .Mui-focused,
    .MuiFormLabel-filled {
      &.MuiInputLabel-root {
        color: $blue_petrol_strong !important;
        top: -8px;
      }

      fieldset {
        border-width: 1px !important;
        border-color: $blue_petrol_strong !important;
        legend {
          max-width: 0;
        }
      }
    }
    // Error
    .Mui-error,
    .MuiFormHelperText-root {
      color: $text;
      &.MuiInputLabel-root {
        color: $red_alert !important;
      }
      &.MuiOutlinedInput-root {
        fieldset {
          border-color: $red_alert !important;
        }
      }
      &.MuiFormHelperText-root {
        color: $red_alert;
      }
    }
  }
}

// overwrite modal select
#menu- .MuiPaper-elevation1 {
  max-width: 320px !important;
  width: 320px !important;
  border-radius: 8px;
  margin-top: 10px;
  // left: 0 !important;
  // padding: 6px 0;
  .MuiMenuItem-root.menuAll, .MuiMenuItem-root.region, .MuiMenuItem-root.departement {
    &:hover {
      background: none;
      color: inherit;
    }
  }
  .MuiMenuItem-root{
    font-family: "Circular-Std-Book", Arial, sans-serif !important;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    .MuiTouchRipple-root {
      display: none;
    }
    .checkboxButton.gray {
      padding: 0;
      background: none;
      
    }
    .checkboxButton {
      label {
        &:last-child {
          padding-right: 20px;
        }
      }
    }
    &.region {
      .arrowSvg {
        svg {
          path {
            fill: $text;
          }
        }
      }
      .arrowSvg {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        height: 24px;
        align-items: center;
      }
    }
    &.region, &.department {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    
  }
  .MuiMenuItem-root.menuAll {
    background: none;
    justify-content: flex-start;
    .checkboxButton {
      label {
        padding-right: 0;
      }
    }
  }
  .MuiCheckbox-indeterminate svg path {
    background: $white;
    fill: $blue;
    color: $blue;
  }
  .Mui-selected {
    background: $blue;
    color: $white;
  }
  .Mui-focusVisible {
    background: $blue;
    color: $white;
  }
  .wrapper_departement {
    margin-left: 13px;
    &.hide {
        display: none;

    }
    &.open {
        display: block;
    }
    
  }
}
